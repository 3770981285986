import { isInIframe } from './is-in-iframe';

const getRedirectToOFMRouteReq = (route: string): Record<string, unknown> => ({
  version: '1.0',
  messageType: 'navigateInternal',
  payloadVersion: '1.0',
  payload: {
    route,
  },
});

export const goToOFMRoute = (url: string): void => {
  if (isInIframe()) {
    window.parent.postMessage(getRedirectToOFMRouteReq(url), '*');
  } else {
    window.postMessage(getRedirectToOFMRouteReq(url), '*');
  }
};
