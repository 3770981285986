import { getParentOrigin } from './getParentOrigin';
import { isInIframe } from './is-in-iframe';

export const extractPathFromUrl = (href: string): string => {
  try {
    const url = new URL(href);
    return url.pathname + url.search + url.hash;
  } catch {
    return href;
  }
};

export const isInternaOFMlLink = async (href: string): Promise<boolean> => {
  try {
    let parentOrigin: string;
    if (isInIframe()) {
      parentOrigin = await getParentOrigin();
    } else {
      parentOrigin = window.location.origin;
    }
    const url = new URL(href);
    return url.origin === parentOrigin;
  } catch {
    return true;
  }
};
