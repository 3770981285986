import React from 'react';
import ReactDOM from 'react-dom';
import { Root } from '@components/root';
import services, { ServiceProvider } from '@services/service.provider';
import { initGA } from '@utils/GA';
import { Provider } from 'react-redux';
import { store } from '@store';
import { addLocationChangeEvent } from '@utils/add-location-change-event';
import { logOutIfNeedTo, subscribeToTokenUpdateEvent, updateOfmAuthorizedData } from '@utils/handle-ofm-token-update';
import { isInIframe } from '@utils/is-in-iframe';
import { delay } from '@utils/delay';
import { saveIframeCredsByTokenUpdate } from '@utils/post-iframe-creds';

export async function initApp(
  appRootNode: HTMLElement,
  isBrowser: boolean,
  hasHiddenAttribute: boolean,
): Promise<void> {
  updateOfmAuthorizedData();

  services.fullHeightService.init(appRootNode);
  services.botcopyService.init();
  services.loggingService.init();
  if (isInIframe()) {
    await delay(500);
  }
  await Promise.all([services.settingsService.init(), services.statusService.init()]);
  await initGA(services.settingsService.settings.GoogleAnalyticMeasurementId);
  services.brandingService.init(appRootNode);
  services.routerService.init(isBrowser);
  logOutIfNeedTo(services.routerService, services.twilioConversationsService);
  addLocationChangeEvent();
  saveIframeCredsByTokenUpdate();
  subscribeToTokenUpdateEvent(services.twilioConversationsService);

  if (isInIframe()) {
    await delay(500);
  }

  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <ServiceProvider value={services}>
          <Root isBrowser={isBrowser} hasHiddenAttribute={hasHiddenAttribute} />
        </ServiceProvider>
      </Provider>
    </React.StrictMode>,
    appRootNode,
  );
}
