/* eslint-disable @typescript-eslint/no-explicit-any */
import { EventsEnum } from '../eventType.enum';
import { store } from '@store';
import { OfmSliceActions } from '@slices/ofm.slice';
import axios from 'axios';
import { TwilioConversationsService } from '@services/twilio-conversations/twilio-conversations.service';
import { isInIframe } from './is-in-iframe';
import { SessionStorageService } from '@services/storage/session-storage.service';
import { storageServicePrefix } from '@services/storage/storage.service';
import { RouterService } from '@services/router/router.service';
import { LogOutCredsKey, LogOutTimeKey } from './post-iframe-creds';
import { Nullable } from '@models/nullable.type';
import { ITwilioCredentials } from '@api/session';
import { isMobile } from 'react-device-detect';
import services from '@services/service.provider';
import { goToOFMRoute } from './goToOFMRoute';
const axiosInstance = axios.create();

export interface IAuthorizationData {
  token: string;
  document: string;
  sessionId: string;
  apiEndpoint: string;
  apiKey: string;
  anonymousToken?: string;
  embedded?: string;
}

export interface IAuthorizationRequestBody {
  OfmSessionId: string;
  OfmUserToken: string;
  ClientDeviceId: string;
  ApiKey: string;
  anonymousToken?: string;
  embedded?: string;
}

interface IIframeAuthorizationEventData {
  eventName: string;
  authorizationData: IAuthorizationData;
}

const getOfmAuthorizedSessionRequest = (authData: IAuthorizationData): IAuthorizationRequestBody => {
  const { saved } = JSON.parse(authData.document);
  const ofmSessionId = authData.sessionId;
  axiosInstance.defaults.headers.common['authorization'] = `Token ${authData.token}`;
  axiosInstance.defaults.headers.common['x-api-key'] = `${authData.apiKey}`;
  axiosInstance.defaults.headers.common['x-client-device-id'] = saved.clientDeviceID;
  axiosInstance.defaults.headers.common['x-session-id'] = ofmSessionId;

  const authorizationData: IAuthorizationRequestBody = {
    OfmSessionId: ofmSessionId,
    OfmUserToken: authData.token,
    ClientDeviceId: saved.clientDeviceID,
    ApiKey: authData.apiKey,
    embedded: authData.embedded,
  };

  if (authData.anonymousToken) {
    authorizationData.anonymousToken = authData.anonymousToken;
  }

  return authorizationData;
};

const authOfmStorageKey = `${storageServicePrefix}${SessionStorageService.AuthInOfm}`;
const ofmAuthorizedDataStorageKey = `${storageServicePrefix}${SessionStorageService.OfmAuthorizedData}`;
const accessTokenKey = `${storageServicePrefix}${SessionStorageService.AccessToken}`;
export const isChatOpenKey = `${storageServicePrefix}${SessionStorageService.IsChatOpen}`;
export const subscribeToTokenUpdateEvent = (twilioConversationsService: TwilioConversationsService): void => {
  const ofmAuthorizationChangeHandler = (authData: IAuthorizationData): void => {
    //services.routerService.closeApp();
    const isAuth = !!authData?.token;
    const newToken = authData.anonymousToken;

    if (!isInIframe() || newToken) {
      twilioConversationsService.endChat(true);
    }

    store.dispatch(OfmSliceActions.setOfmAuthState({ isAuth }));
    sessionStorage[authOfmStorageKey] = isAuth;

    if (!isAuth) {
      store.dispatch(OfmSliceActions.resetOfmAuthState());
      sessionStorage.removeItem(authOfmStorageKey);
      sessionStorage.removeItem(ofmAuthorizedDataStorageKey);
    } else {
      const authorizationData = getOfmAuthorizedSessionRequest(authData);
      store.dispatch(OfmSliceActions.setOfmAuthorizationData({ authorizationData }));
      sessionStorage[ofmAuthorizedDataStorageKey] = JSON.stringify(authorizationData);
    }
  };

  const getAuthorizationData = (): IAuthorizationData => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const { apiEndpoint, apiKey } = JSON.parse((document.getElementById('app-settings') as any).text);
    const authData: IAuthorizationData = {
      token: getOFMAuthToken(),
      sessionId: sessionStorage['ast.orpheus.ds-session-id'],
      document: localStorage['ast.orpheus.document'],
      apiEndpoint: apiEndpoint,
      apiKey: apiKey,
      embedded: sessionStorage['ast.orpheus.embedded'],
    };

    const anonymousToken = getAccessToken();
    if (anonymousToken) {
      authData.anonymousToken = anonymousToken;
    } else {
      authData.anonymousToken = undefined;
    }

    return authData;
  };

  const messageEvent = 'message';
  const iframeLiveChatReadyEvent = 'as.cc.livechatready';
  const iframeOfmAuthorizedEvent = 'as.cc.ofmauthorized';

  // for local development of authorized chat
  const isLocalDevelopment = process.env.NODE_ENV === 'development';
  if (isInIframe() || isLocalDevelopment) {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    window.addEventListener(messageEvent, (event: any): void => {
      const eventData: IIframeAuthorizationEventData = event.data;

      if (eventData?.eventName !== iframeOfmAuthorizedEvent) {
        return;
      }

      let idToCompare;
      try {
        idToCompare = JSON.parse(sessionStorage[ofmAuthorizedDataStorageKey])?.OfmSessionId;
      } catch (e) {
        console.error(e);
      }
      if (eventData.authorizationData.sessionId !== idToCompare) {
        sessionStorage.clear();
      }
      services.sessionService.init();
      ofmAuthorizationChangeHandler(eventData.authorizationData);
    });
    window.parent.postMessage(iframeLiveChatReadyEvent, '*');
  } else {
    window.addEventListener(EventsEnum.TokenUpdate, (): void => {
      if (services.sessionService.isAuthSessionCreating) {
        return;
      }

      const authData = getAuthorizationData();
      if (!authData.token) {
        sessionStorage.setItem(LogOutTimeKey, `${Date.now()}`);
      }

      if (authData.token && !services.sessionService.isAuthSessionCreated) {
        console.warn('All requests will be canceled!');
        services.twilioConversationsService.cancelRequestsOnInit();
      }

      ofmAuthorizationChangeHandler(authData);
    });

    // eslint-disable-next-line
    window.addEventListener(messageEvent, (event: any): void => {
      if (event.data !== iframeLiveChatReadyEvent) {
        return;
      }

      try {
        let savedAuthData: IAuthorizationData | null = null;
        try {
          savedAuthData = JSON.parse(sessionStorage[ofmAuthorizedDataStorageKey]);
        } catch (error) {
          console.warn('no anonymous data');
        }

        const authData = getAuthorizationData();
        const iframeEvent: IIframeAuthorizationEventData = {
          eventName: iframeOfmAuthorizedEvent,
          authorizationData: { ...authData },
        };

        if (savedAuthData?.anonymousToken) {
          iframeEvent.authorizationData.anonymousToken = savedAuthData.anonymousToken;
        } else {
          iframeEvent.authorizationData.anonymousToken = undefined;
        }

        const frames = window.frames as any;
        frames.livechatiframe.postMessage(iframeEvent, '*');

        if (savedAuthData) {
          savedAuthData.anonymousToken = undefined;
          sessionStorage.setItem(ofmAuthorizedDataStorageKey, JSON.stringify(savedAuthData));
          store.dispatch(OfmSliceActions.setOfmAuthorizationData({ authorizationData: savedAuthData }));
        }
      } catch (e) {
        console.error(`Iframe handler to auth failed: `, e);
      }
    });
  }
};
export const updateOfmAuthorizedData = (): void => {
  const isAuthFromOfm = !!getOFMAuthToken();
  const isAuth = sessionStorage[authOfmStorageKey];
  const authorizationData = sessionStorage[ofmAuthorizedDataStorageKey];

  if (isAuth && authorizationData) {
    // If OFM posts TokenUpdate when site is closed or by some reason
    // we should check additionally that our auth data and ofm's the same
    if (!isAuthFromOfm && !isInIframe()) {
      sessionStorage.removeItem(authOfmStorageKey);
      sessionStorage.removeItem(ofmAuthorizedDataStorageKey);
      return;
    }

    new Date().getSeconds();
    store.dispatch(OfmSliceActions.setOfmAuthState({ isAuth: JSON.parse(isAuth) }));
    store.dispatch(OfmSliceActions.setOfmAuthorizationData({ authorizationData: JSON.parse(authorizationData) }));
  }
};

const getAccessToken = (): string | undefined => {
  try {
    const result = JSON.parse(sessionStorage[accessTokenKey]);
    return result;
  } catch (error) {
    console.warn(`access token doesn't exist`);
  }
};

export const logOutIfNeedTo = (
  routerService: RouterService,
  twilioConversationsService: TwilioConversationsService,
): void => {
  const isOnOfm = sessionStorage['ast.orpheus.ds-session-id'];
  if (!isOnOfm) {
    return;
  }

  const isAuthFromOfm = !!getOFMAuthToken();
  const logOutData = sessionStorage[LogOutCredsKey];
  const nowDate = new Date().getTime();
  const logOutDate = new Date(Number(sessionStorage.getItem(LogOutTimeKey)) || 0).getTime();
  const isTimeoutExpired = Math.round((nowDate - logOutDate) / 1000) > 15; // more than 15 seconds

  if (!isAuthFromOfm && logOutData) {
    routerService.setFirstInitialRoute();
    if (!isTimeoutExpired) {
      const data = JSON.parse(logOutData) as { credentials: Nullable<ITwilioCredentials>; token: string };
      twilioConversationsService.sendLogOutFromOfmSystemMessage(data.credentials, data.token);
    }

    sessionStorage.removeItem(LogOutTimeKey);
    sessionStorage.removeItem(LogOutCredsKey);
  }
};

export const openChatByAuth = (routerService: RouterService): void => {
  // 30-46 rows must(!) be replaced to services
  try {
    const isChatOpen = JSON.parse(sessionStorage[isChatOpenKey]);
    const isAuthFromOfm = !!getOFMAuthToken();

    if (isChatOpen && !isMobile && isAuthFromOfm) {
      routerService.openApp();
      routerService.openChat();
    } else if (isChatOpen && isMobile && !isInIframe() && isAuthFromOfm) {
      goToOFMRoute(`/livechat`);
    }
  } catch (error) {
    console.warn('No isChatOpen session storeage variable');
  }

  routerService.isAppOpen$.subscribe((value) => {
    sessionStorage[isChatOpenKey] = JSON.stringify(value);
  });
};

export const getOFMAuthToken = (): string => sessionStorage['ast.orpheus.auth-token'];
