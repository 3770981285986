import { ISendSystemMessageResponse } from '@api/send-system-message';
import { Button } from '@components/button';
import { CustomInput } from '@components/custom-input';
import { InfoMessage } from '@components/info-message';
import { Loader } from '@components/loader';
import { Scroller } from '@components/scroller';
import { AbstractStatusEnum } from '@models/abstract-status.enum';
import { ServiceContext } from '@services/service.provider';
import { capitalize, stripStringByFormat, validateEmail } from '@utils/helpers';
import classnames from 'classnames';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';
import styles from './customer-identification-form.module.css';
import { StringByFormatEnum } from '@models/helpers';
import { SsnFormatEnum } from '@api/settings';
import { load } from 'recaptcha-v3';
import { ChatActions } from '@slices/chat.slice';
import { ApiErrorCodeEnum } from '@api/specialError';
import { Recaptchav2Container } from '@components/recaptcha-v2-container';
import Reaptcha from 'reaptcha';
import { useVoiceForLabels } from '@hooks/use-voice-for-labels';
import { useFocusOnRef } from '@hooks/use-focus-on-ref';
import { useSayPhrase } from '@hooks/use-say-phrase';
import { useAppDispatch, useAppSelector } from '@store';

const PhoneLength = 10;
const LiveOfFormTimeout = 20 * 1000;

export const CustomerIdentificationForm: React.FunctionComponent = () => {
  const { routerService, settingsService, liveChatService } = useContext(ServiceContext);
  const previousMemberNumber = useAppSelector((state) => state.chat.previousMemberNumber);
  const [isFormTouched, setIsFormTouched] = useState(false);
  const [memberNumber, setMemberNumber] = useState('');
  const [phoneNumber, setPhoneNumber] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [message, setMessage] = useState('');
  const [email, setEmail] = useState('');
  const [ssn, setSsn] = useState('');
  const dispatch = useAppDispatch();
  const timerId = useRef(0);
  const [isShowRecaptchaV2, setIsShowRecaptchaV2] = useState(false);
  const [recaptchaV2Token, setRecaptchaV2Token] = useState('');
  const recaptchaV2Ref = useRef<Reaptcha>(null);

  const customerIdentificationForm = settingsService.settings.AuthSettings;
  const { SsnLast4Digits, Ssn, FirstName, LastName, PhoneNumber, Email, Close, Submit, Loading, RecaptchaProtected } =
    settingsService.settings.LiveChatTextResources;

  const ssnSettings = useMemo(() => {
    const is4digitsMask = customerIdentificationForm.Ssn.Format === SsnFormatEnum.FourDigits;
    const ssnMask = is4digitsMask ? '0000' : '000-00-0000';
    const isSsnInvalid = ssn.replace(/\D/g, '').length < (is4digitsMask ? 4 : 9);
    const ssnTitle = is4digitsMask ? SsnLast4Digits : Ssn;

    return {
      isSsnInvalid,
      ssnTitle,
      ssnMask,
    };
  }, [customerIdentificationForm.Ssn.Format, ssn, SsnLast4Digits, Ssn]);

  const isPhoneInvalid = phoneNumber.length < PhoneLength;
  const isEmailInvalid = !validateEmail(email);

  const resetPrefilledIfAnotherMember = useCallback((): void => {
    if (previousMemberNumber && previousMemberNumber !== memberNumber) {
      liveChatService.resetTransferInfo();
    }
    dispatch(ChatActions.setNextMemberNumber(memberNumber));
  }, [dispatch, liveChatService, memberNumber, previousMemberNumber]);

  const skipHandler = (): void => {
    routerService.openChat();
    liveChatService.skipCustomerIdentificationForm();
  };

  const processingResponse = useCallback(
    (response: ISendSystemMessageResponse): void => {
      if (response.Error) {
        if (response.Error.Code === ApiErrorCodeEnum.RecaptchaScoreWasLowerThenMinimal) {
          setIsShowRecaptchaV2(true);
          setRecaptchaV2Token('');
          setIsLoading(false);
        } else {
          setIsShowRecaptchaV2(false);
          setRecaptchaV2Token('');
          setMessage(response.Error.Message);
          setIsLoading(false);
        }
      } else {
        setIsShowRecaptchaV2(false);
        setRecaptchaV2Token('');
        resetPrefilledIfAnotherMember();
        timerId.current = window.setTimeout(() => {
          setMessage(customerIdentificationForm.RejectMessage);
          setIsLoading(false);
        }, LiveOfFormTimeout);
      }
    },
    [customerIdentificationForm.RejectMessage, resetPrefilledIfAnotherMember],
  );

  const submitHandler = useCallback(
    async (recaptchaToken: string | null, isRecaptchaV2: boolean): Promise<void> => {
      setMessage('');
      const response = await liveChatService.submitCustomerIdentificationForm(
        {
          number: memberNumber || null,
          firstName: firstName || null,
          lastName: lastName || null,
          phone: stripStringByFormat(phoneNumber, StringByFormatEnum.Digits) || null,
          email: email || null,
          ssn: stripStringByFormat(ssn, StringByFormatEnum.Digits) || null,
        },
        settingsService.settings.RecaptchaSettings.RecaptchaIsEnabled,
        isRecaptchaV2,
        recaptchaToken,
      );

      processingResponse(response);
    },
    [
      email,
      firstName,
      lastName,
      liveChatService,
      memberNumber,
      phoneNumber,
      processingResponse,
      settingsService.settings.RecaptchaSettings.RecaptchaIsEnabled,
      ssn,
    ],
  );

  const onSubmitButtonHandler = useCallback(async () => {
    if (
      (customerIdentificationForm.MemberNumber.IsRequired && !memberNumber) ||
      (customerIdentificationForm.PhoneNumber.IsRequired && isPhoneInvalid) ||
      (customerIdentificationForm.FirstName.IsRequired && !firstName) ||
      (customerIdentificationForm.Email.IsRequired && isEmailInvalid) ||
      (customerIdentificationForm.LastName.IsRequired && !lastName) ||
      (customerIdentificationForm.Ssn.IsRequired && !ssn) ||
      ssnSettings.isSsnInvalid
    ) {
      setIsFormTouched(true);
    } else {
      setIsLoading(true);
      if (settingsService.settings.RecaptchaSettings.RecaptchaIsEnabled) {
        if (recaptchaV2Token) {
          submitHandler(recaptchaV2Token, true);
        } else {
          const recaptcha = await load(settingsService.settings.RecaptchaSettings.RecaptchaV3SiteKey, {
            useRecaptchaNet: false,
            autoHideBadge: true,
          });
          const token = await recaptcha.execute('submit');
          submitHandler(token, false);
        }
      } else {
        submitHandler(null, false);
      }
    }
  }, [
    customerIdentificationForm.Email.IsRequired,
    customerIdentificationForm.FirstName.IsRequired,
    customerIdentificationForm.LastName.IsRequired,
    customerIdentificationForm.MemberNumber.IsRequired,
    customerIdentificationForm.PhoneNumber.IsRequired,
    customerIdentificationForm.Ssn.IsRequired,
    firstName,
    isEmailInvalid,
    isPhoneInvalid,
    lastName,
    memberNumber,
    recaptchaV2Token,
    settingsService.settings.RecaptchaSettings.RecaptchaIsEnabled,
    settingsService.settings.RecaptchaSettings.RecaptchaV3SiteKey,
    ssn,
    ssnSettings.isSsnInvalid,
    submitHandler,
  ]);

  useEffect(() => {
    return (): void => {
      if (timerId.current) {
        window.clearTimeout(timerId.current);
      }
    };
  }, []);

  useEffect(() => {
    if (recaptchaV2Token !== '') {
      onSubmitButtonHandler();
    }
  }, [onSubmitButtonHandler, recaptchaV2Token]);

  const formRef = useVoiceForLabels();
  const refToFocus = useFocusOnRef(formRef);
  useSayPhrase(
    isLoading ? 'customer identification form is submitted, data is loading, please, wait a few seconds' : '',
    true,
  );
  return (
    <Scroller>
      <form className={classnames(styles.form, { [styles.formDisabled]: isLoading })} noValidate ref={formRef}>
        <Loader isLoading={isLoading} />
        <div
          className={styles.title}
          data-qa="auth_info_title"
          tabIndex={0}
          ref={isLoading ? null : refToFocus}
          aria-label={`form heading, ${customerIdentificationForm.Title}, use tab to navigate to the next element`}
        >
          {customerIdentificationForm.Title}
        </div>
        <div className={styles.fields}>
          {customerIdentificationForm.MemberNumber.IsVisible && (
            <CustomInput
              label={customerIdentificationForm.MemberNumber.Label}
              value={memberNumber}
              setValue={setMemberNumber}
              qaLocator="auth_info_member_number"
              isRequired={customerIdentificationForm.MemberNumber.IsRequired}
              isFormTouched={isFormTouched}
              maxLength={20}
              format={StringByFormatEnum.Digits}
              autoComplete="off"
            />
          )}
          {customerIdentificationForm.Ssn.IsVisible && (
            <CustomInput
              label={ssnSettings.ssnTitle}
              value={ssn}
              setValue={setSsn}
              qaLocator="auth_info_SSN"
              isRequired={customerIdentificationForm.Ssn.IsRequired}
              isInvalid={ssnSettings.isSsnInvalid}
              isFormTouched={isFormTouched}
              mask={ssnSettings.ssnMask}
              autoComplete="off"
            />
          )}
          {customerIdentificationForm.FirstName.IsVisible && (
            <CustomInput
              label={capitalize(FirstName)}
              value={firstName}
              setValue={setFirstName}
              qaLocator="auth_info_first_name"
              isRequired={customerIdentificationForm.FirstName.IsRequired}
              isFormTouched={isFormTouched}
              format={StringByFormatEnum.Name}
              autoComplete="off"
            />
          )}
          {customerIdentificationForm.LastName.IsVisible && (
            <CustomInput
              label={capitalize(LastName)}
              value={lastName}
              setValue={setLastName}
              qaLocator="auth_info_last_name"
              isRequired={customerIdentificationForm.LastName.IsRequired}
              isFormTouched={isFormTouched}
              format={StringByFormatEnum.Name}
              autoComplete="off"
            />
          )}
          {customerIdentificationForm.PhoneNumber.IsVisible && (
            <CustomInput
              label={capitalize(PhoneNumber)}
              value={phoneNumber}
              setValue={setPhoneNumber}
              mask="(000) 000-0000"
              qaLocator="auth_info_phone_number"
              isInvalid={isPhoneInvalid}
              isRequired={customerIdentificationForm.PhoneNumber.IsRequired}
              isFormTouched={isFormTouched}
              autoComplete="off"
            />
          )}
          {customerIdentificationForm.Email.IsVisible && (
            <CustomInput
              label={capitalize(Email)}
              value={email}
              setValue={setEmail}
              qaLocator="auth_info_email"
              isInvalid={isEmailInvalid}
              isRequired={customerIdentificationForm.Email.IsRequired}
              isFormTouched={isFormTouched}
              autoComplete="off"
            />
          )}
        </div>
        {message && <InfoMessage type={AbstractStatusEnum.negative} message={message} />}
        <div className={styles.buttons} data-qa="auth_info_btns">
          <Button
            onClick={skipHandler}
            disabled={isLoading}
            qaLocator="auth_info_close_btn"
            ariaLabel={'button, close, press enter to close customer identification form'}
          >
            {capitalize(Close)}
          </Button>
          <Button
            onClick={onSubmitButtonHandler}
            primary
            disabled={isLoading || isShowRecaptchaV2}
            qaLocator="auth_info_submit_btn"
            ariaLabel={'button, submit, press enter to submit customer identification form'}
          >
            {isLoading ? `${capitalize(Loading)}...` : capitalize(Submit)}
          </Button>
        </div>

        {settingsService.settings.RecaptchaSettings.RecaptchaIsEnabled && isShowRecaptchaV2 && (
          <div className={styles.grecaptchaRobot}>
            <Recaptchav2Container
              recaptchaV2Ref={recaptchaV2Ref}
              setIsShowRecaptchaV2={setIsShowRecaptchaV2}
              setRecaptchaV2Token={setRecaptchaV2Token}
            />
          </div>
        )}

        {settingsService.settings.RecaptchaSettings.RecaptchaIsEnabled && (
          <div className={styles.grecaptchaLabels}>
            <p dangerouslySetInnerHTML={{ __html: RecaptchaProtected }}></p>
          </div>
        )}
      </form>
    </Scroller>
  );
};
