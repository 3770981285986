import { ILogRequest } from '@api/ILogRequest';
import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, CancelTokenSource } from 'axios';
import { ILivechatSettings } from './settings';
import { IMessagesRequest, IMessagesResponse } from './messages';
import { IRateRequest } from './rate';
import { ISendMessageRequest } from './send-message';
import { ISendSystemMessageResponse } from './send-system-message';
import { IStatusResponse } from './status';
import { IRefreshTokenRequest, IRefreshTokenResponse } from './refresh-token';
import { ITwilioTokenResponse } from './twilio-token';
import {
  IAuthSessionRequest,
  IJoinSessionResponse,
  ISessionRequest,
  ISessionResponse,
  IStateSessionResponse,
  ITwilioCredentials,
} from './session';
import { composeSystemMessageBody } from '@utils/compose-system-message-body';
import { SystemMessageTypeEnum } from '@models/system-message-type.enum';
import { Nullable } from '@models/nullable.type';
import { SeparatedChatModeEnum } from '@models/chat-mode.enum';
import { SessionStorageService } from '@services/storage/session-storage.service';
import { IAuthorizationRequestBody } from '@utils/handle-ofm-token-update';
import { getIdForClientDeviceIdHeader } from '@utils/get-id-for-client-device-id-header';
import { getLocale } from '@utils/get-locale';

export class HTTPClient {
  private _identity = 'test-identity';
  private _scriptApplicationContext: string | undefined;
  private _source: CancelTokenSource;

  public static readonly OfmContext = 'ofm';
  public static readonly MfmContext = 'mfm';
  public static readonly AuthHeaderKey = 'X-AST-ContactCenter-Auth-Token';
  public static readonly ApplicationContextHeaderKey = 'X-AST-ApplicationContext';
  public static readonly XAstApplicationOrigin = 'X-AST-ApplicationOrigin';
  public static readonly SessionIdHeaderKey = 'X-AST-SessionId';
  public static readonly ApplicationIdHeaderKey = 'X-AST-ClientApplicationId';
  public static readonly TenantIdHeaderKey = 'x-tenant-id';
  public static readonly ClientDeviceIdHeaderKey = 'X-Client-DeviceId';
  public static readonly ClientLocale = 'X-AST-Lang';
  public static readonly MissingTenantIdInLocationMessage =
    'Expected the deepest domain should contain tenantId after first dash';

  public static readonly MissingTenantIdInScriptAttributeMessage =
    'Expected the script tag should contain tenant id in data-tenant-id attribute';

  public static readonly MissingApplicationInScriptAttributeMessage =
    'Expected the script tag should contain application context in data-application-context attribute';

  public static Endpoints = class {
    public static Authorization = class {
      public static refreshToken = '/livechat/web/token/refresh';
    };

    public static Livechat = class {
      public static authSession = '/livechat/authorized/session';
      public static session = '/livechat/web/session';
      public static joinSession = '/livechat/web/session/join';
      public static stateSession = '/livechat/web/session/state';
      public static refreshTwilioToken = '/livechat/web/token/twilio/refresh';
      public static messages = '/livechat/web/channel/messages';
      public static add = '/livechat/web/channel/messages/add';
      public static systemAdd = '/livechat/web/channel/messages/system/add';
      public static rate = '/livechat/web/channel/rate';
      public static settings = '/livechat/settings';
      public static features = '/livechat/features';
      public static status = '/livechat/status';
    };

    public static Configuration = class {
      public static branding = 'configuration/branding';
    };

    public static Log = class {
      public static write = '/api/log';
    };
  };

  public tenantId: string;

  public static isAuthorizedRequest(request: AxiosRequestConfig): boolean {
    const anonymousRequestEndpoints = [
      HTTPClient.Endpoints.Authorization.refreshToken,
      HTTPClient.Endpoints.Livechat.session,
      HTTPClient.Endpoints.Livechat.authSession,
      HTTPClient.Endpoints.Livechat.settings,
      HTTPClient.Endpoints.Livechat.features,
      HTTPClient.Endpoints.Livechat.status,
    ];
    return !anonymousRequestEndpoints.some((endpoint) => request.url?.endsWith(endpoint));
  }

  constructor(
    private _http: AxiosInstance,
    private _window: Window,
    private _sessionStorageService: SessionStorageService,
  ) {
    this.tenantId = '';
    if (this._isTest) {
      this.tenantId = 'test';
    } else if (this._isDevelopment) {
      this.tenantId = this.getTenantIdFromDomain();
    } else {
      this.tenantId = this.getTenantIdFromScript();
    }

    this._http.defaults.baseURL = this._getBaseUrl();
    this._http.defaults.validateStatus = (status: number): boolean => 200 <= status && status < 500;

    this._scriptApplicationContext = this._window.document.currentScript?.dataset.applicationContext;
    this.refreshApplicationContext();
    this._addHeader(HTTPClient.XAstApplicationOrigin, this._getOrigin());
    this._addHeader(HTTPClient.ApplicationIdHeaderKey, `livechat_${this._getVersion()}`);
    this._addHeader(HTTPClient.ClientDeviceIdHeaderKey, getIdForClientDeviceIdHeader());
    const { CancelToken } = axios;
    this._source = CancelToken.source();
    this._http.defaults.cancelToken = this._source.token;
  }

  private get _isDevelopment(): boolean {
    return process.env.NODE_ENV === 'development';
  }

  private get _isTest(): boolean {
    return process.env.NODE_ENV === 'test';
  }

  private _getBaseUrl(): string {
    let url = '@contactcenterurl';

    if (this._isDevelopment) {
      url = 'https://contactcenter-devops-dev01.orpheusdev.net/contactcenter/v1';
    }

    //if (typeof process === 'object' && process.env.isDevelopmentWithAuthChat) {
    //TODO: today it's only stand with ofm auth process. We need to clear approach to run that.
    // The code above's crashed bilding
    if (this._isDevelopment && document.location.href.includes('videochatfiagent-vipsafedev13-01')) {
      url = 'https://contactcenter-devops-dev01.orpheusdev.net/contactcenter/v1';
    }
    if (this._isDevelopment && document.location.href.includes('ofmweb-keysafeqa09')) {
      url = 'https://contactcenter-devops-qasafe.orpheusdev.net/contactcenter/v1';
    }

    return url;
  }

  private _getVersion(): string {
    return '1.38.0324.1047';
  }

  public getTenantIdFromDomain(): string {
    const AllAfterFirstDashInDeepestDomainRegexp = /(?=-)([^.]+)/;
    const matchArray = this._window.location.hostname.match(AllAfterFirstDashInDeepestDomainRegexp);
    if (matchArray === null) {
      throw new ReferenceError(HTTPClient.MissingTenantIdInLocationMessage);
    }
    // cut first dash, because of lookbehind regexp isn't supported in some browsers.
    return matchArray[0].substring(1);
  }

  public getTenantIdFromScript(): string {
    const tenantId = this._window.document.currentScript?.dataset.tenantId;
    if (!tenantId) {
      throw new ReferenceError(HTTPClient.MissingTenantIdInScriptAttributeMessage);
    }
    return tenantId;
  }

  public refreshApplicationContext(): void {
    const applicationContext =
      this._isDevelopment || this._isTest ? HTTPClient.OfmContext : this.getApplicationContext();
    this._addHeader(HTTPClient.ApplicationContextHeaderKey, applicationContext);
  }

  public getApplicationContext(): string {
    const applicationContext = this._scriptApplicationContext;
    if (!applicationContext) {
      throw new ReferenceError(HTTPClient.MissingApplicationInScriptAttributeMessage);
    }

    const authData = this._sessionStorageService.get<IAuthorizationRequestBody>(
      SessionStorageService.OfmAuthorizedData,
    );
    //Bug https://ji.mfmnow.com/browse/CCVLC-4473
    //we can use only one webview.html for ofm => we should calculate context manually (we can't set it in attributes)
    if (applicationContext.toLocaleLowerCase() === HTTPClient.OfmContext && authData?.embedded === 'true') {
      return HTTPClient.MfmContext;
    }

    return applicationContext;
  }

  private _getOrigin(): string {
    const { hash, origin, pathname } = this._window.location;
    return `${origin}${pathname}${hash}`;
  }

  public async session(chatMode?: SeparatedChatModeEnum): Promise<ISessionResponse> {
    const body: ISessionRequest = {
      BrowserInfo: this._identity,
      Origin: this._getOrigin(),
    };

    if (chatMode) {
      body.LiveChatMode = chatMode;
    }
    const { data } = await this._http.post<ISessionResponse>(HTTPClient.Endpoints.Livechat.session, body);
    return data;
  }

  public async authSession(
    requestBody: IAuthSessionRequest,
    chatMode?: SeparatedChatModeEnum,
  ): Promise<ISessionResponse> {
    this._addHeader(HTTPClient.XAstApplicationOrigin, this._getOrigin());
    const body = requestBody;
    if (chatMode) {
      body.LiveChatMode = chatMode;
    }
    const { data } = await this._http.post<ISessionResponse>(HTTPClient.Endpoints.Livechat.authSession, body);
    return data;
  }

  public async joinSession(): Promise<IJoinSessionResponse> {
    const { data } = await this._http.post<IJoinSessionResponse>(HTTPClient.Endpoints.Livechat.joinSession);
    return data;
  }

  public async stateSession(): Promise<IStateSessionResponse> {
    const { data } = await this._http.post<IStateSessionResponse>(HTTPClient.Endpoints.Livechat.stateSession);
    return data;
  }

  public async refreshTwilioToken(): Promise<ITwilioTokenResponse> {
    const { data } = await this._http.post<ITwilioTokenResponse>(HTTPClient.Endpoints.Livechat.refreshTwilioToken, {});
    return data;
  }

  public async refreshToken(token: string): Promise<IRefreshTokenResponse> {
    const body: IRefreshTokenRequest = { RefreshToken: token };
    const { data } = await this._http.post<IRefreshTokenResponse>(
      HTTPClient.Endpoints.Authorization.refreshToken,
      body,
    );
    return data;
  }

  public async messages(body: IMessagesRequest): Promise<IMessagesResponse> {
    const { data } = await this._http.post<IMessagesResponse>(HTTPClient.Endpoints.Livechat.messages, body);
    return data;
  }

  public async sendMessage(body: ISendMessageRequest): Promise<unknown> {
    const { data } = await this._http.post(HTTPClient.Endpoints.Livechat.add, body);
    return data;
  }

  public async sendSystemMessage(
    type: SystemMessageTypeEnum,
    credentials: Nullable<ITwilioCredentials>,
    attributes: Record<string, unknown> = {},
    headers: Record<string, string> = {},
    singleRequestHeaders?: Record<string, string>,
  ): Promise<ISendSystemMessageResponse> {
    const body = composeSystemMessageBody(type, credentials, attributes);
    for (const [key, value] of Object.entries(headers)) {
      this._addHeader(key, value);
    }

    const { data } = await this._http.post<ISendSystemMessageResponse>(HTTPClient.Endpoints.Livechat.systemAdd, body, {
      headers: singleRequestHeaders,
    });
    return data;
  }

  public async rate(body: IRateRequest): Promise<void> {
    await this._http.post(HTTPClient.Endpoints.Livechat.rate, body);
  }

  public async settings(): Promise<ILivechatSettings> {
    const { data } = await this._http.post<ILivechatSettings>(HTTPClient.Endpoints.Livechat.settings);
    await this._http.post<ILivechatSettings>(HTTPClient.Endpoints.Livechat.features);
    return data;
  }

  public async setLocalizationIfEnabled(): Promise<void> {
    const { data } = await this._http.post<{ IsLocalizationEnabled: boolean }>(HTTPClient.Endpoints.Livechat.features);
    if (data.IsLocalizationEnabled) {
      this._sessionStorageService.save(SessionStorageService.IsLocalizationEnabled, true);
      this._addHeader(HTTPClient.ClientLocale, getLocale());
    }
  }

  public async writeLog(body: ILogRequest): Promise<void> {
    await this._http.post(HTTPClient.Endpoints.Log.write, body);
  }

  public async status(): Promise<boolean> {
    const { data } = await this._http.post<IStatusResponse>(HTTPClient.Endpoints.Livechat.status);
    return data.IsOnline;
  }

  // try to move twilio credentials to headers, not body
  // discuss with backend team
  public addSessionIdHeader(sessionId: string): void {
    this._addHeader(HTTPClient.SessionIdHeaderKey, sessionId);
  }

  public removeSessionIdHeader(): void {
    this._removeHeader(HTTPClient.SessionIdHeaderKey);
  }

  public addResponseInterceptor(
    onFulfilled?: (value: AxiosResponse) => Promise<AxiosResponse>,
    onRejected?: (error: unknown) => typeof error,
  ): number {
    return this._http.interceptors.response.use(onFulfilled, onRejected);
  }

  public addRequestInterceptor(onFulfilled?: (value: AxiosRequestConfig) => Promise<AxiosRequestConfig>): number {
    return this._http.interceptors.request.use(onFulfilled);
  }

  public cancelRequests(): void {
    this._source.cancel();
  }

  private _addHeader(key: string, value: string): void {
    this._http.defaults.headers.common[key] = value;
  }

  private _removeHeader(key: string): void {
    delete this._http.defaults.headers.common[key];
  }
}
