import React, { useContext, useEffect, useRef } from 'react';
import styles from './menu.module.css';
import { Scroller } from '@components/scroller';
import { useBehaviorSubject } from '@hooks/use-behavior-subject';
import { ServiceContext } from '@services/service.provider';
import { ChatModeEnum, SeparatedChatModeEnum } from '@models/chat-mode.enum';
import { sendGAEvent } from '@utils/GA';
import { Icon } from '@components/icon';
import { isMobile } from 'react-device-detect';

export const Menu: React.FunctionComponent = () => {
  const { botcopyService, settingsService, twilioConversationsService, routerService } = useContext(ServiceContext);
  const {
    ContactUsMenuButton,
    PhoneUsMenuButton,
    Greeting,
    LiveChatMenuButton,
    BotCopyMenuButton,
    LiveChatBotMenuButton,
  } = settingsService.settings;
  const isBotCopyEnabled = botcopyService.isEnabled;
  const isChatBotReady = useBehaviorSubject(botcopyService.isReady$);
  const isSeparatedMode = settingsService.settings.Mode === ChatModeEnum.SeparatedLiveChatBot;

  const onAgentChatHandler = (): void => {
    if (isSeparatedMode) {
      twilioConversationsService.openChatByMenuButton(SeparatedChatModeEnum.LiveChat);
    } else {
      routerService.openChat();
    }
    sendGAEvent('User', 'Chat with agent opened');
  };

  const onBotChatHandler = (): void => {
    twilioConversationsService.openChatByMenuButton(SeparatedChatModeEnum.LiveChatBot);
    sendGAEvent('User', 'Chat with bot opened');
  };

  const containerRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const buttons = containerRef.current?.querySelectorAll('[data-element=button]');
    if (!isMobile) {
      const title = containerRef.current?.querySelector('[data-element="heading"]') as HTMLElement;
      title?.focus();
    }

    if (buttons) {
      [...buttons].forEach((button, index) => {
        const span = button.querySelector('span');
        button.ariaLabel = `button ${index + 1} of ${buttons.length}, ${span?.innerHTML}, press enter to open`; // TODO: remove `button` from `aria-label`;
      });
    }
  }, [containerRef]);

  return (
    <Scroller>
      <div className={styles.menu} ref={containerRef}>
        <div
          data-element="heading"
          className={styles.title}
          aria-label={`heading, ${Greeting}, use tab to navigate to the next element`}
        >
          {Greeting}
        </div>
        <div>
          <button
            onClick={onAgentChatHandler}
            className={styles.button}
            type="button"
            data-qa="menu_livechat_button"
            data-element="button"
          >
            <Icon name="user" sizes={[24]} className={styles.icon} />
            <span>{LiveChatMenuButton.Title}</span>
          </button>
          {isSeparatedMode && (
            <button
              onClick={onBotChatHandler}
              className={styles.button}
              type="button"
              data-qa="menu_livechat_button"
              data-element="button"
            >
              <Icon name="bot" sizes={[24]} className={styles.icon} />
              <span>{LiveChatBotMenuButton.Title}</span>
            </button>
          )}
          {isBotCopyEnabled && (
            <button
              onClick={(): void => botcopyService.openWindow()}
              className={styles.button}
              type="button"
              disabled={!isChatBotReady}
              data-qa="menu_24_hours_assistant_button"
              data-element="button"
            >
              <Icon name="bot" sizes={[24]} className={styles.icon} />
              <span>{BotCopyMenuButton.Title}</span>
            </button>
          )}
          {PhoneUsMenuButton.IsVisible && (
            <a
              target="_blank"
              href={`tel:${PhoneUsMenuButton.Phone}`}
              rel="noreferrer"
              className={styles.button}
              data-qa="menu_call_us_link"
              title={PhoneUsMenuButton.Title}
              data-element="button"
              role="button"
            >
              <Icon name="call" sizes={[24]} className={styles.icon} />
              <span>{PhoneUsMenuButton.Title}</span>
            </a>
          )}
          {ContactUsMenuButton.IsVisible && (
            <a
              target="_blank"
              href={`mailto:${ContactUsMenuButton.Email}`}
              rel="noreferrer"
              className={styles.button}
              data-qa="menu_email_us_link"
              title={ContactUsMenuButton.Title}
              data-element="button"
              role="button"
            >
              <Icon name="envelope" sizes={[24]} className={styles.icon} />
              <span>{ContactUsMenuButton.Title}</span>
            </a>
          )}
        </div>
      </div>
    </Scroller>
  );
};
