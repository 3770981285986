import { useCallback } from 'react';
import { goToOFMRoute } from '@utils/goToOFMRoute';
import { extractPathFromUrl, isInternaOFMlLink } from '@utils/isInternalLink';
import { isInIframe } from '@utils/is-in-iframe';
import { useAppSelector } from '@store';

type UseGoToOFMRoute = {
  handleShadowRootClick: (event: Event) => void;
};

export const useGoToOFMRoute = (): UseGoToOFMRoute => {
  const isAuth = useAppSelector((state) => state.ofm.isAuth);

  const goToOFMRouteCallback = useCallback((url: string): void => {
    const path = extractPathFromUrl(url);
    goToOFMRoute(path);
  }, []);

  const handleShadowRootClick = useCallback(
    (event: Event) => {
      const target = event.target as HTMLElement;
      if (target.tagName === 'A') {
        const href = target.getAttribute('href');
        if (!href) {
          return;
        }

        event.preventDefault();

        const targetAttr = target.getAttribute('target');

        isInternaOFMlLink(href).then((isInternalLink) => {
          if (isInternalLink && isInIframe() && isAuth) {
            goToOFMRouteCallback(href);
          } else {
            if (targetAttr === '_blank') {
              window.open(href, '_blank');
            } else {
              window.location.href = href;
            }
          }
        });
      }
    },
    [goToOFMRouteCallback, isAuth],
  );

  return { handleShadowRootClick };
};
